<template>
  <div class="container">
    <div class="page-header mb-1">
      <strong>จัดการข้อมูล</strong>
    </div>

    <table class="table table-hover">
      <thead>
        <tr>
          <th width="50%">ข้อมูล</th>
          <th width="30%">จำนวน</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>จำนวนโพย (tickets)</td>
          <td>{{ totalTicket | amountFormat(0) }}</td>
          <td class="text-right">
            <button class="btn btn-danger btn-sm" @click="deleteTickets">ลบทั้งหมด</button>
          </td>
        </tr>
        <tr>
          <td>จำนวนเลข (items)</td>
          <td>{{ totalItem | amountFormat(0) }}</td>
          <td class="text-right">
            <button class="btn btn-danger btn-sm" @click="deleteItems">ลบทั้งหมด</button>
          </td>
        </tr>
        <tr>
          <td>จำนวนคิว (queue)</td>
          <td>{{ totalQueue | amountFormat(0) }}</td>
          <td class="text-right">
            <button class="btn btn-danger btn-sm" @click="deleteQueues">ลบทั้งหมด</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import LottoService from '@/services/lottoService'
import QueueService from '@/services/queueService'
import Swal from 'sweetalert2'

export default {
  name: 'RoundData',
  data() {
    return {
      totalTicket: 0,
      totalItem: 0,
      totalQueue: 0
    }
  },
  computed: {
    roundId() {
      return this.$store.state.globalMarket.round.roundId
    }
  },
  watch: {
    roundId() {
      if(this.roundId) {
        this.reload(this.roundId)
      }
    }
  },
  methods: {
    getTotalTicket(roundId) {
      LottoService.totalTicket(roundId)
      .then((response)=>{
        if(response.success) {
          this.totalTicket = response.data
        }
      })
    },
    getTotalItem(roundId) {
      LottoService.totalItem(roundId)
      .then((response)=>{
        if(response.success) {
          this.totalItem = response.data
        }
      })
    },
    getTotalQueue(roundId) {
      QueueService.totalQueue(roundId)
      .then((response)=>{
        if(response.success) {
          this.totalQueue = response.data
        }
      })
    },
    deleteTickets() {
      Swal.fire({
        title: 'ยืนยันลบข้อมูล?',
        text: "กรุณาตรวจสอบให้ถูกต้องก่อนกดยืนยัน",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
        preConfirm: () => {
          return LottoService.deleteTickets(this.roundId)
            .then((response)=>{
              if(response.success) {
                return response.data
              }else{
                throw new Error(response?.message || JSON.stringify(response?.data))
              }
            })
            .catch((error)=>{
              Swal.fire(
                'ผิดพลาด!',
                (error.message||'ลบข้อมูลไม่สำเร็จ'),
                'error'
              )
            })
        },
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            'สำเร็จ!',
            'ลบข้อมูลสำเร็จ',
            'success'
          )
          .then(()=>{
            this.reload(this.roundId)
          })
        }
      })
    },
    deleteItems() {
      Swal.fire({
        title: 'ยืนยันลบข้อมูล?',
        text: "กรุณาตรวจสอบให้ถูกต้องก่อนกดยืนยัน",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
        preConfirm: () => {
          return LottoService.deleteItems(this.roundId)
            .then((response)=>{
              if(response.success) {
                return response.data
              }else{
                throw new Error(response?.message || JSON.stringify(response?.data))
              }
            })
            .catch((error)=>{
              Swal.fire(
                'ผิดพลาด!',
                (error.message||'ลบข้อมูลไม่สำเร็จ'),
                'error'
              )
            })
        },
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            'สำเร็จ!',
            'ลบข้อมูลสำเร็จ',
            'success'
          )
          .then(()=>{
            this.reload(this.roundId)
          })
        }
      })
    },
    deleteQueues() {
      Swal.fire({
        title: 'ยืนยันลบข้อมูล?',
        text: "กรุณาตรวจสอบให้ถูกต้องก่อนกดยืนยัน",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
        preConfirm: () => {
          return QueueService.deleteQueues(this.roundId)
            .then((response)=>{
              if(response.success) {
                return response.data
              }else{
                throw new Error(response?.message || JSON.stringify(response?.data))
              }
            })
            .catch((error)=>{
              Swal.fire(
                'ผิดพลาด!',
                (error.message||'ลบข้อมูลไม่สำเร็จ'),
                'error'
              )
            })
        },
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            'สำเร็จ!',
            'ลบข้อมูลสำเร็จ',
            'success'
          )
          .then(()=>{
            this.reload(this.roundId)
          })
        }
      })
    },
    reload(roundId) {
      if(!roundId)
        return

      this.getTotalTicket(roundId)
      this.getTotalItem(roundId)
      this.getTotalQueue(roundId)
    }
  },
  mounted() {
    this.reload(this.roundId)
  }
}
</script>
<style lang="scss" scoped>
.table {
  background-color: #FFF;

  tbody {
    tr {
      td {
        vertical-align: middle;
      }
    }
  }
}
</style>
